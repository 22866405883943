<template>
  <!-- <nav class="navbar navbar-expand-lg bg-info mb-3">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Youtarin</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active" aria-current="page"
              >خانه</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/channels"
              class="nav-link active"
              aria-current="page"
              >کانال ها</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/videos"
              class="nav-link active"
              aria-current="page"
              >ویدیوها</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link active" aria-current="page"
              >درباره</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav> -->
  <router-view />
</template>
